import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import accountClient from 'api/accountClient';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiryWrapper';
import { getAzureLoginUrl, SignUpSignInJourneyStep } from 'helpers/azureRoutingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { CsMeta } from 'types/contentStack';

type FullRegistrationProps = {
  data: {
    csFullRegistration: {
      meta: CsMeta;
    };
  };
};

export const query = graphql`
  query {
    csFullRegistration {
      meta {
        meta_title
      }
    }
  }
`;

const FullRegistration: React.FC<FullRegistrationProps> = ({
  data: {
    csFullRegistration: { meta },
  },
}) => {
  const { requestHandler } = useApiRequestHandler();

  useEffect(() => {
    requestHandler(() =>
      accountClient.getNonce().then(({ nonce }) => {
        window.location.replace(
          getAzureLoginUrl(SignUpSignInJourneyStep.Register, nonce)
        );
      })
    );
  }, [requestHandler]);

  return (
    <Layout
      meta={meta}
      pageType="accountArea"
      sessionExpiryOption={SessionExpiryOption.SILENT_SESSION_EXPIRY}
      hideAccountMenu>
      <LoadingOverlay loadingMessage="Redirecting to registration page" />
    </Layout>
  );
};

export default FullRegistration;
